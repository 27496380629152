import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Create,
  Datagrid,
  Edit,
  ImageField,
  List,
  SimpleForm,
  TextField,
  TextInput,
  required,
  ImageInput,
  FormTab,
  TabbedForm,
  ReferenceManyField,
  DeleteButton,
  Button,
  email,
  SelectInput,
  NumberInput,
  BooleanField,
  useNotify,
  useRecordContext,
  Labeled,
  TabbedFormTabs,
  ReferenceField,
  BooleanInput,
  BulkDeleteButton,
  Confirm,
  useListContext,
  useRefresh,
  useUpdateMany,
  Toolbar,
  SaveButton,
  useRedirect,
  ReferenceInput,
  useUpdate,
  AutocompleteInput,
  usePermissions,
  SelectArrayInput,
  SelectArrayInputProps,
} from 'react-admin';
import { Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  emailProvidersChoices,
  isHexColor,
  BrandLocalesChoices,
  vettingMethod,
  AUTO_DETECT_OPTION,
} from '../consts';
import IncentiveSetup from '../components/Campaign/IncentiveSetup/IncentiveSetup';
import { EmptyTextualFieldProps, cleanEmptyValues } from '../utils/general';
import { checkEmailSenderVerification } from '../providers/dataProvider';
import CustomTosForm from '../components/Brands/CustomTosForm';
import { stickyToolbar } from './toolbar';
import { Warning } from '@mui/icons-material';
import { ChipInput } from '../components/ChipInput';
import { find, groupBy, map, isEmpty } from 'lodash';
import { Role } from './users';
import { useFormContext } from 'react-hook-form';

const BulkActions = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { data, selectedIds, onToggleItem } = useListContext();

  const selectedRecords = map(selectedIds, id => find(data, { id }));
  const { true: deleted, false: notDeleted } = groupBy(
    selectedRecords,
    'isDeleted',
  );

  const [open, setOpen] = React.useState(false);
  const [restoreMany, { isLoading }] = useUpdateMany(
    'brands',
    {
      ids: map(deleted, ({ id }) => `${id}/restore`),
    },
    {
      mutationMode: 'optimistic',
      onSuccess: () => {
        notify('Brands restored', { type: 'info' });
        deleted.forEach(({ id }) => onToggleItem(id));
        refresh();
      },
      onError: () => {
        notify('Error: Brands restore failed', { type: 'error' });
      },
      onSettled: () => {
        setOpen(false);
      },
    },
  );

  return (
    <>
      {!isEmpty(notDeleted) && (
        <BulkDeleteButton
          confirmTitle="Archive brands"
          confirmContent={`Are you sure you want to archive ${notDeleted.length} brands?`}
          mutationMode="pessimistic"
          label="Archive"
        />
      )}
      {!isEmpty(deleted) && (
        <>
          <Button label="Restore" onClick={() => setOpen(true)} />
          <Confirm
            isOpen={open}
            loading={isLoading}
            title="Restore brands?"
            content={`Are you sure you want to restore ${deleted.length} brands?`}
            onConfirm={() => restoreMany()}
            onClose={() => setOpen(false)}
          />
        </>
      )}
    </>
  );
};

export const BrandList: React.FC = (props: any) => {
  const { permissions: role } = usePermissions();

  return (
    <List
      filters={[
        <ReferenceInput source="accountId" reference="accounts" alwaysOn>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>,
        <ChipInput source="q" label="Search" alwaysOn />,
      ].concat(
        role === Role.ADMIN ? (
          <BooleanInput source="includeDeleted" alwaysOn />
        ) : (
          []
        ),
      )}
      {...props}
    >
      <Datagrid bulkActionButtons={<BulkActions />} rowClick="edit">
        <TextField source="id" />
        <TextField source="name" label="Brand" />
        <ReferenceField source="accountId" reference="accounts">
          <TextField source="name" label="Account" />
        </ReferenceField>
        <ImageField source="logoURL" label="Logo" />
        <TextField source="brandKit.colors.textColor" />
        <TextField source="brandKit.colors.backgroundColor" />
        <BooleanField source="isDeleted" label="Archived" />
      </Datagrid>
    </List>
  );
};

const AddIntegrationButton = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Button
      component={Link}
      variant="contained"
      to={`/integrations/create?brandId=${record?.id}`}
      label="Add Integration"
      title="Add Integration"
    >
      <AddIcon />
    </Button>
  );
};

const CheckSenderVerificationButton = () => {
  const notify = useNotify();
  const record = useRecordContext();

  if (!record) return null;

  const { id: brandId, emailSender } = record;

  return (
    <Button
      label="Check for verification"
      disabled={emailSender && emailSender?.verified}
      onClick={async () => {
        try {
          const res = await checkEmailSenderVerification(brandId);
          if (res.verified) {
            notify('Sender is verified', { type: 'success' });
          } else {
            notify('Sender is not verified', { type: 'warning' });
          }
        } catch (err) {
          console.error(err);
          notify('Something went wrong', { type: 'error' });
        }
      }}
      variant="contained"
    />
  );
};

const EMAIL_FIELDS_TO_VALIDATE = [
  'fromEmail',
  'fromName',
  'replyToEmail',
  'country',
  'city',
  'address',
];

const validateEmailFields = (value: any, values: any) => {
  if (values.emailSender) {
    const checkEmailSender = Object.keys(values.emailSender).filter(
      key =>
        EMAIL_FIELDS_TO_VALIDATE.indexOf(key) > -1 &&
        (!values.emailSender[key] || values.emailSender[key] === ''),
    );

    if (checkEmailSender.length > 0) {
      if (checkEmailSender.length === EMAIL_FIELDS_TO_VALIDATE.length)
        return 'Cannot remove an existing sender - must be replaced';
      else {
        return 'All email sender fields must be filled for it to be saved';
      }
    }
  }

  return undefined;
};

const validateMaxSelectedLanguages = (value: any) => {
  if (value.length > 5) {
    return 'You can select maximum 5 languages';
  }
  return undefined;
};

const CustomToolbar = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const record = useRecordContext<{ isDeleted: boolean; id: string }>();
  const [open, setOpen] = React.useState(false);
  const [restore, { isLoading }] = useUpdate(
    'brands',
    {
      id: `${record.id}/restore`,
      data: { id: record.id },
    },
    {
      onSuccess: () => {
        redirect('/brands');
        notify('Brand restored', { type: 'info' });
      },
      onError: () => {
        notify('Error: Brand restore failed', { type: 'error' });
      },
      onSettled: () => {
        setOpen(false);
      },
      mutationMode: 'pessimistic',
    },
  );

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton />
      {record.isDeleted ? (
        <>
          <Button label="Restore" onClick={handleClick} />
          <Confirm
            isOpen={open}
            loading={isLoading}
            title="Restore Brand?"
            content="Are you sure you want to restore this item?"
            onConfirm={() => restore()}
            onClose={handleDialogClose}
          />
        </>
      ) : (
        <DeleteButton
          mutationOptions={{
            onSettled: (_, err) => {
              if (!err) {
                notify('Brand archived', { type: 'info' });
              }
            },
          }}
          confirmContent="Are you sure you want to archive this brand?"
          confirmTitle="Archive Brand"
          mutationMode="pessimistic"
          label="Archive"
        />
      )}
    </Toolbar>
  );
};

const LanguageSelectInput: React.FC<SelectArrayInputProps> = props => {
  const { setValue, getValues } = useFormContext();

  const handleChange: SelectArrayInputProps['onChange'] = event => {
    const value = event.target.value;

    if (value.includes(AUTO_DETECT_OPTION)) {
      setValue('languages', [AUTO_DETECT_OPTION]);
      return;
    }

    const currentValues = getValues('languages') || [];
    const filteredValues = currentValues.includes(AUTO_DETECT_OPTION)
      ? value.filter((lang: string) => lang !== AUTO_DETECT_OPTION)
      : value;
    setValue('languages', filteredValues);
  };

  return <SelectArrayInput {...props} onChange={handleChange} />;
};

export const BrandEdit = (props: any) => (
  <Edit
    {...props}
    transform={cleanEmptyValues}
    mutationMode="pessimistic"
    className={stickyToolbar().root}
  >
    <TabbedForm
      toolbar={<CustomToolbar />}
      tabs={<TabbedFormTabs variant="scrollable" scrollButtons={false} />}
    >
      <FormTab label="summary">
        <TextInput source="id" disabled />
        <TextInput source="name" isRequired validate={[required()]} />
        <ReferenceInput source="accountId" reference="accounts">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ImageInput
          source="logoURL"
          label="Logo"
          accept="image/png,image/jpeg,image/jpg"
          maxSize={2 * 1024 * 1024}
          helperText="File above 2MB will be rejected"
          format={(value: string | object) =>
            typeof value === 'string' ? { url: value } : value
          }
        >
          <ImageField source="url" title="title" />
        </ImageInput>
        <ImageInput
          source="fullLogoURL"
          label="Full Logo"
          accept="image/png,image/jpeg,image/jpg"
          maxSize={2 * 1024 * 1024}
          helperText="File above 2MB will be rejected"
          format={(value: string | object) =>
            typeof value === 'string' ? { url: value } : value
          }
        >
          <ImageField source="url" title="title" />
        </ImageInput>
        <ReferenceInput
          source="industryId"
          reference="industries"
          isRequired
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <LanguageSelectInput
          label="Platform Transcribe Languages"
          source="languages"
          choices={BrandLocalesChoices}
          style={{ maxWidth: '193px' }}
          validate={validateMaxSelectedLanguages}
        />
        <Typography variant="h5">Subscription Tier</Typography>
        <NumberInput
          source="platformLimit"
          isRequired
          validate={[required()]}
        />
        <SelectInput
          source="vettingMethod"
          label="Video session vetted by"
          choices={vettingMethod}
          validate={[required()]}
          defaultValue="MODERATOR"
        />
      </FormTab>
      <FormTab label="styles">
        <Typography variant="h5">General</Typography>
        <TextInput
          source="brandKit.colors.textColor"
          isRequired
          validate={[required(), isHexColor()]}
          defaultValue="#232528"
          label="Text Color"
          {...EmptyTextualFieldProps}
        />
        <TextInput
          source="brandKit.colors.backgroundColor"
          isRequired
          validate={[required(), isHexColor()]}
          defaultValue="#FFFFFF"
          label="Background Color"
          {...EmptyTextualFieldProps}
        />
        <TextInput source="font" label="Font" {...EmptyTextualFieldProps} />
        <Typography variant="h5">Buttons</Typography>
        <TextInput
          source="brandKit.colors.buttonTextColor"
          isRequired
          validate={[required(), isHexColor()]}
          defaultValue="#FFFFFF"
          label="Text Color"
          {...EmptyTextualFieldProps}
        />
        <TextInput
          source="brandKit.colors.buttonBackgroundColor"
          isRequired
          validate={[required(), isHexColor()]}
          defaultValue="#232528"
          label="Background Color"
          {...EmptyTextualFieldProps}
        />
      </FormTab>
      <FormTab label="Emails">
        <Typography variant="h5">Configuration</Typography>
        <SelectInput
          source="emailProvider"
          choices={emailProvidersChoices}
          label="Outreach channel"
        />
        <Grid container spacing={5}>
          <Grid item xs={2}>
            <Typography variant="h5">Sender</Typography>
            <Labeled label="Verified">
              <BooleanField source="emailSender.verified" looseValue />
            </Labeled>
          </Grid>
          <Grid item xs={5} display={'flex'}>
            <Warning color={'error'} sx={{ marginRight: '10px' }} />
            <Grid>
              <Typography
                fontSize={'12px'}
                variant="body2"
                style={{ textDecorationLine: 'underline' }}
              >
                Warning:
              </Typography>
              <Typography variant="body2" fontSize={'12px'}>
                changing each of the following fields will trigger another
              </Typography>
              <Typography variant="body2" fontSize={'12px'}>
                verification email (except from uplifted.ai domain)
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={8} display={'flex'}>
          <Grid item xs={2} display={'flex'} direction={'column'}>
            <TextInput
              source="emailSender.fromName"
              label="Sender Name"
              {...EmptyTextualFieldProps}
              validate={validateEmailFields}
            />
            <TextInput
              source="emailSender.fromEmail"
              label="From Email"
              validate={[email(), validateEmailFields]}
              {...EmptyTextualFieldProps}
            />
            <TextInput
              source="emailSender.replyToEmail"
              label="Reply to Email"
              validate={[email(), validateEmailFields]}
              {...EmptyTextualFieldProps}
            />
          </Grid>
          <Grid item xs={2} display={'flex'} direction={'column'}>
            <TextInput
              source="emailSender.address"
              label="Address"
              {...EmptyTextualFieldProps}
              validate={validateEmailFields}
            />
            <TextInput
              source="emailSender.city"
              label="City"
              {...EmptyTextualFieldProps}
              validate={validateEmailFields}
            />
            <TextInput
              source="emailSender.country"
              label="Country"
              {...EmptyTextualFieldProps}
              validate={validateEmailFields}
            />
          </Grid>
        </Grid>
        <CheckSenderVerificationButton />
      </FormTab>
      <FormTab label="Integrations">
        <ReferenceManyField reference="integrations" target="brandId">
          <Datagrid>
            <TextField source="id" />
            <TextField source="vendor" />
            <TextField source="settings" />
            <DeleteButton redirect={false} />
          </Datagrid>
        </ReferenceManyField>
        <AddIntegrationButton />
      </FormTab>
      <FormTab label="Marketing platforms">
        <NumberInput
          source="marketingPlatformsVideos.FACEBOOK"
          label="Facebook / Instagram"
          min={0}
        />
        <NumberInput
          source="marketingPlatformsVideos.TIKTOK"
          label="TikTok"
          min={0}
        />
        <NumberInput
          source="marketingPlatformsVideos.YOUTUBE"
          label="Youtube"
          min={0}
        />
        <NumberInput
          source="marketingPlatformsVideos.REELS"
          label="Reels"
          min={0}
        />
        <NumberInput
          source="marketingPlatformsVideos.SNAPCHAT"
          label="Snapchat"
          min={0}
        />
        <NumberInput
          source="marketingPlatformsVideos.WEBSITE"
          label="Website"
          min={0}
        />
      </FormTab>
      <FormTab label="Incentive Setup">
        <IncentiveSetup showButtons={false} />
      </FormTab>
      <FormTab label="Custom TOS">
        <CustomTosForm />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const BrandCreate = (props: any) => {
  const notify = useNotify();

  return (
    <Create
      {...props}
      transform={cleanEmptyValues}
      mutationMode="pessimistic"
      className={stickyToolbar().root}
      mutationOptions={{
        onError: (error: any) => {
          const { statusCode, message } = error?.body;

          if (message?.[0]?.includes('incentiveSetting')) {
            notify('Please fill the default incentive settings Form', {
              type: 'error',
            });
            return;
          }

          if (statusCode === 400) {
            notify('Please fill missing fields', { type: 'error' });
            return;
          }

          notify('Failed to create brand', { type: 'error' });
          return;
        },
      }}
    >
      <SimpleForm>
        <TextInput source="id" disabled />
        <TextInput source="name" isRequired validate={[required()]} />
        <ReferenceInput source="accountId" reference="accounts">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ImageInput
          source="logoURL"
          label="Logo"
          accept="image/png,image/jpeg,image/jpg"
          maxSize={2 * 1024 * 1024}
          helperText="File above 2MB will be rejected"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput
          source="fullLogoURL"
          label="Full Logo"
          accept="image/png,image/jpeg,image/jpg"
          maxSize={2 * 1024 * 1024}
          helperText="File above 2MB will be rejected"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ReferenceInput
          source="industryId"
          reference="industries"
          isRequired
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <Typography variant="h5">Subscription Tier</Typography>
        <NumberInput
          source="platformLimit"
          isRequired
          validate={[required()]}
        />
        <SelectInput
          source="vettingMethod"
          label="Video session vetted by"
          choices={vettingMethod}
          defaultValue="MODERATOR"
        />
        <Typography variant="h5">Email</Typography>
        <SelectInput
          source="emailProvider"
          choices={emailProvidersChoices}
          label="Outreach channel"
          defaultValue="CAST_EMAIL"
        />
        <TextInput
          source="emailSender.fromName"
          label="Sender Name"
          {...EmptyTextualFieldProps}
          validate={validateEmailFields}
        />
        <TextInput
          source="emailSender.fromEmail"
          label="From Email"
          validate={[email(), validateEmailFields]}
          {...EmptyTextualFieldProps}
        />
        <TextInput
          source="emailSender.replyToEmail"
          label="Reply to Email"
          validate={[email(), validateEmailFields]}
          {...EmptyTextualFieldProps}
        />
        <TextInput
          source="emailSender.address"
          label="Address"
          {...EmptyTextualFieldProps}
          validate={validateEmailFields}
        />
        <TextInput
          source="emailSender.city"
          label="City"
          {...EmptyTextualFieldProps}
          validate={validateEmailFields}
        />
        <TextInput
          source="emailSender.country"
          label="Country"
          {...EmptyTextualFieldProps}
          validate={validateEmailFields}
        />
        <Typography variant="h5">Styles</Typography>
        <Typography variant="h6">General</Typography>
        <TextInput
          source="brandKit.colors.textColor"
          validate={[required(), isHexColor()]}
          defaultValue="#232528"
          label="Text Color"
          {...EmptyTextualFieldProps}
        />
        <TextInput
          source="brandKit.colors.backgroundColor"
          validate={[required(), isHexColor()]}
          defaultValue="#FFFFFF"
          label="Background Color"
          {...EmptyTextualFieldProps}
        />
        <TextInput
          source="brandKit.font"
          label="Font"
          defaultValue="Inter"
          {...EmptyTextualFieldProps}
        />
        <Typography variant="h6">Buttons</Typography>
        <TextInput
          source="brandKit.colors.buttonTextColor"
          validate={[required(), isHexColor()]}
          defaultValue="#FFFFFF"
          label="Text Color"
          {...EmptyTextualFieldProps}
        />
        <TextInput
          source="brandKit.colors.buttonBackgroundColor"
          validate={[required(), isHexColor()]}
          defaultValue="#232528"
          label="Background Color"
          {...EmptyTextualFieldProps}
        />
        <Typography variant="h6">Default Incentive</Typography>
        <IncentiveSetup showButtons={false} />
      </SimpleForm>
    </Create>
  );
};
